import React, {useEffect, useState} from 'react';
// import React from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import Universities from './pages/Universities/Universities';
import Programs from './pages/Programs/Programs';
import Subjects from './pages/Subjects/Subjects';

import UniReview from './pages/subpages/UniReview/UniReview';

import Menubar from './components/MenuBar/Menubar';
import Home from "./home/Home";
import Login from "./user/Login/Login";
import Profile from "./user/Profile Stats/Profile Stats";
import SignUp from "./user/SignUp/SignUp";
import DetailView from "./pages/subpages/DetailView/DetailView";

import AuthenticationManager from "./components/AuthenticationInforamtionAndManager/AuthenticationManager";
import {API} from "./API";

import SCLogo from './images/logos/SCLogo.png';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const password = prompt('Please enter the password:');
        authenticate(password);
    }, []);

    const authenticate = async (password) => {
        try {
            const value = {
                identifier: "mainlogin@studentschoice.blog",
                password: password,
            };

            const response = await fetch(`${API}/auth/local`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            });

            const result = await response.json();

            if (result.user.id === 194) {
                setIsAuthenticated(true);
            } else {
                alert('Incorrect password! The site will not load.');
            }
        } catch (error) {
            console.error('Error during authentication:', error);
        }
    };

    if (!isAuthenticated) {
        return (
            <div style={{
                backgroundColor: '#6b0221',
                color: 'white',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center'
            }}>
                <img src={SCLogo} alt="Logo" className="headerImageAlignment"/>

                <h1>Under Construction</h1>
                <p>Come Back Soon!</p>
            </div>
        );
    }

    return (
        <AuthenticationManager>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Menubar />}>
                        <Route index element={<Home />} />
                        <Route path="unireview" element={<UniReview />} />
                        <Route path="universities" element={<Universities />} />
                        <Route path="programs" element={<Programs />} />
                        <Route path="subjects" element={<Subjects />} />
                        <Route path="login" element={<Login />} />
                        <Route path="signup" element={<SignUp />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="/universities/:idUniversity/program/:idDetail" element={<DetailView />} />
                        <Route path="/universities/:idUniversity/subject/:idDetail" element={<DetailView />} />
                        <Route path="/universities/:idUniversity" element={<UniReview />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthenticationManager>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
